@import "../../scss/Player.scss";

.playlist-player-container.player-container {
    height: 100vh;

    &.player-container--bandit-manchot {
        .player-header {
            height: 155px;

            .player-header__exit {
                background-color: #C4C4C450; // TODO: Sort out color with client variables
                color: white;
                font-size: 24px;
                border-radius: 31px;
                height: 100px;
                width: 150px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .exit__icon {
                    font-size: 50px;
                }
            }

            .player-header__progression {
                flex: 1 0 auto;
                max-width: 500px;
                text-align: center;

                h2 {
                    color: white;
                    font-family: 'Roboto', sans-serif;
                    font-size: 36px;
                    font-weight: 700;
                    margin-bottom: 16px;
                }
            }
        }
        .main__wrapper main {
            // 204 = 155px header + 49px footer
            height: calc(100vh - 204px);
        }
    }
    
    .player-header {
        height: 68px;
    }

    .main__wrapper {
        position: relative;
        height: 100%;

        main {
            // 117 = 68 px header + 49px footer
            height: calc(100vh - 117px);
            display: flex;
            flex-direction: column-reverse; /* 'column' for start, 'column-reverse' for end */
            overflow-y: scroll;

            // You'll break the chatbot greyed background if you add position here
        }
    }
}
