@import "../../scss/Variables.scss";

.dashboard {
    padding: 32px 24px;
    h1 {
        margin: 0 0 24px 0;
    }
    img {
        display: block;
        margin: 0 auto;
        @include adaptiveWidth(500);
    }
}
